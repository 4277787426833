<template>
  <Page
    :subtitle="`${profile.fname || ' '} ${profile.lname || ''}`"
    :loading="loading"
    has-actions
  >
    <template slot="actions">
      <b-dropdown
        :key="!!profile.active"
        aria-role="list"
        position="is-bottom-left"
        scrollable
        max-height="400px"
        append-to-body
      >
        <div slot="trigger">
          <b-button
            icon-left="dots-vertical"
            :size="$device.mobile ? 'is-small' : ''"
            type="is-default"
            rounded
          />
        </div>

        <b-dropdown-item
          v-if="$can('read', 'settings.agents') && !!profile.active"
          aria-role="listitem"
          @click="requestPassword()"
        >
          <b-icon
            icon="lock"
            size="is-small"
          />
          {{ $t("request_password") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('delete', 'settings.agents') && !!profile.active"
          aria-role="listitem"
          @click="confirmDeleting()"
        >
          <b-icon
            icon="delete"
            type="is-danger"
            size="is-small"
          />
          {{ $t("delete") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('delete', 'settings.agents') && !profile.active"
          aria-role="listitem"
          @click="restore()"
        >
          <b-icon
            icon="refresh"
            type="is-success"
            size="is-small"
          />
          {{ $t("restore") }}
        </b-dropdown-item>
      </b-dropdown>
    </template>




    <div
      v-if="!loading"
      class="profile columns"
    >
      <div class="column is-3">
        <Card
          class="menu"
          content-class="p-3 columns is-multiline is-mobile m-0"
        >
          <div
            class="column is-4"
            :class="{
              'bg-deleted': !profile.active
            }"
          >
            <img
              src="/img/avatars/blank.svg"
              class="avatar"
              :alt="profile.fname"
              width="100%"
            >
          </div>

          <div
            class="column is-8 is-flex is-flex-direction-column is-justify-content-center"
            :class="{
              'bg-deleted': !profile.active
            }"
          >
            <span class="mb-1">
              <b>{{ profile.fname }} {{ profile.lname }}</b>
            </span>
            <div class="is-size-7">
              <span>{{ profile.role.label }}</span>
            </div>
          </div>

          <div class="column is-12">
            <hr class="m-0">
          </div>

          <div class="column is-12 p-0">
            <ul class="tabs-container no-wrap">
              <template v-for="item in tabs">
                <li
                  v-if="
                    item.hasOwnProperty('condition') ? item.condition : true
                  "
                  :key="item.value"
                  :class="{ active: item.id == tab }"
                  @click="tab = item.id"
                >
                  <b-icon
                    :icon="item.icon"
                    custom-size="mdi-18px"
                    class="mr-2"
                  />
                  {{ item.value }}
                  <b-tag
                    v-if="item.comingSoon"
                    size="is-small"
                    type="is-warning"
                  >
                    {{ $t("coming_soon") }}
                  </b-tag>
                </li>
              </template>
            </ul>
          </div>
        </Card>
      </div>
      <div class="column is-9">
        <b-message
          v-if="!profile.active"
          type="is-danger"
        >
          <div class="is-flex is-align-items-center">
            <b-icon
              icon="account"
              type="is-danger"
              class="mr-2"
            />
            <span class="mt-1">
              <b>{{ $tc("inactive", 1) }}</b>
            </span>
          </div>
        </b-message>
        <AgentForm
          v-if="tab == 1"
          :profile="profile"
          :languages="languages"
          :suppliers="suppliers"
          :roles="roles"
          :warehouses="warehouses"
        />
        <AgentPermissions
          v-if="tab == 2"
          :permissions="{
            role: profile.role.permissions,
            agent: profile.permissions
          }"
          :filter-permissions="permissions"
          :languages="languages"
        />
      </div>
    </div>
  </Page>
</template>

<script>
import AgentForm from "@/components/modules/Agent/Update";
import AgentPermissions from "@/components/modules/Agent/Permissions";

export default {
  components: {
    AgentForm,
    AgentPermissions
  },
  data() {
    return {
      loading: true,
      tab: 1,
      tabs: [
        {
          id: 1,
          value: this.$i18n.t("edit"),
          icon: "pencil",
          condition: true
        },
        {
          id: 2,
          value: this.$i18n.tc("permission", 2),
          icon: "shield-account",
          condition: true
        }
      ],
      profile: {},
      languages: [],
      roles: [],
      permissions: [],
      suppliers: [],
      warehouses: []
    };
  },
  created() {
    this.updateTitle(this.$i18n.t("agent_dashboard"));
    if (this.$can("read", "settings.agents")) this.loadAgent();
    this.tab = this.$route.query.tab || 1;
  },
  mounted() {
    this.$bus.$on("update-agent", data => {
      this.profile = data.agent;
    });
  },
  destroyed() {
    this.$bus.$off("update-agent");
  },
  methods: {
    confirmDeleting() {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.delete()
      });
    },
    delete() {
      this.$axios
        .delete("agents/" + this.profile.id)
        .then(() => {
          this.profile = {...this.profile, active: false};
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    restore() {
      this.$axios
        .patch("agents/" + this.profile.id + "/restore")
        .then(() => {
          this.profile = {...this.profile, active: true};
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    requestPassword() {
        this.$axios
          .post("agents/" + this.profile.id + "/password/request")
          .then(() => {
            this.notify(
              this.$i18n.t("request_successfully_sent"),
              "is-success"
            );
          })
          .catch(e => this.clientError(e));
    },
    loadAgent() {
      this.loading = true;
      this.$axios
        .get(`/agents/${this.$route.params.agent_id}`)
        .then(res => {
          this.profile = res.data.agent;
          this.languages = res.data.filters.languages;
          this.permissions = res.data.filters.permissions;
          this.suppliers = res.data.filters.suppliers;
          this.warehouses = res.data.filters.warehouses;
          this.roles = res.data.filters.roles;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>

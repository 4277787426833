<template>
  <Card content-class="">
    <AgentForm
      :profile="profile"
      :action="action"
      :languages="languages"
      :roles="roles"
      :suppliers="suppliers"
      :warehouses="warehouses"
    />
  </Card>
</template>

<script>
import AgentForm from "@/components/forms/Agent";
export default {
  components: {
    AgentForm
  },
  props: {
    profile: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    suppliers: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      action: null
    };
  },
  created() {
    if (this.$can("update", "settings.agents")) {
      this.action = "update";
    } else {
      this.action = "read";
    }
  }
};
</script>

<template>
  <Card content-class="c-table">
    <b-table
      :data="showPermission"
      :total="showPermission.length"
      :row-class="
        (row, index) =>
          `${row.hasBorderBottom ? 'border-thick-bottom ' : ''}${
            row.disabled ? 'has-text-grey' : ''
          }`
      "
      hoverable
      striped
    >
      <b-table-column
        v-slot="props"
        field="label"
        :label="this.$i18n.tc('permission', 1)"
        header-class="sticky-header"
      >
        <b-tooltip
          position="is-right"
          :label="props.row.description"
          type="is-primary is-light"
          multilined
        >
          <span
            v-if="!props.row.disabled && !disabledItems"
            class="is-clickable"
            @click="deleteItem(props.row)"
          >
            <b-icon
              icon="close"
              type="is-danger"
              size="is-small"
            />
          </span>
          {{ props.row.label }}
        </b-tooltip>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="create"
        :label="this.$i18n.t('create')"
        width="80"
        centered
        header-class="sticky-header"
      >
        <b-checkbox
          v-model="props.row.create"
          :disabled="props.row.disabled || disabledItems"
          class="ml-4"
          size="is-small"
        />
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="read"
        :label="this.$i18n.t('view')"
        width="80"
        centered
        header-class="sticky-header"
      >
        <b-checkbox
          v-model="props.row.read"
          :disabled="props.row.disabled || disabledItems"
          class="ml-4"
          size="is-small"
        />
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="update"
        :label="this.$i18n.t('edit')"
        width="100"
        centered
        header-class="sticky-header"
      >
        <b-checkbox
          v-model="props.row.update"
          :disabled="props.row.disabled || disabledItems"
          class="ml-4"
          size="is-small"
        />
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="delete"
        :label="this.$i18n.t('delete')"
        width="80"
        centered
        header-class="sticky-header"
      >
        <b-checkbox
          v-model="props.row.delete"
          :disabled="props.row.disabled || disabledItems"
          class="ml-4"
          size="is-small"
        />
      </b-table-column>

      <EmptyBlock
        slot="empty"
        icon="shield-account"
        :text="$t('agent_have_all_permissions')"
      />

      <div
        slot="footer"
        class="p-2 is-flex is-justify-content-space-between"
      >
        <b-autocomplete-validation
          :key="addedPer"
          v-model="addedPer"
          :disabled="disabledItems || permissions.role.length == 0"
          style="width: 250px"
          class="has-text-weight-normal"
          :label="$t('add')"
          :data="filterPermissions"
          item-text="label"
          field="id"
          hide-details
          clear-on-select
        />

        <b-button
          class="button"
          :disabled="
            update_loading || disabledItems || permissions.role.length == 0
          "
          :loading="update_loading"
          type="is-primary"
          @click="savePermissions"
        >
          {{ $t("save") }}
        </b-button>
      </div>
    </b-table>
  </Card>
</template>

<script>
export default {
  props: {
    permissions: {
      type: Object,
      required: true
    },
    filterPermissions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      update_loading: false,
      disabledItems: !this.$can("update", "settings.agents"),
      agentPermissions: [],
      showPermission: [],
      addedPer: null
    };
  },
  watch: {
    addedPer(id) {
      this.addPermissionToList(id);
    }
  },
  created() {
    for (let i = 0; i < this.permissions.role.length; i++) {
      const per = this.permissions.role[i];
      per.disabled = true;
      this.showPermission.push(per);
    }
    let lastIndex = this.showPermission.length - 1;
    if (lastIndex >= 0) this.showPermission[lastIndex].hasBorderBottom = true;

    this.showPermission = [...this.showPermission, ...this.permissions.agent];
    this.agentPermissions = [...this.permissions.agent];
  },
  methods: {
    addPermissionToList(id) {
      if (id && this.agentPermissions.findIndex(p => p.id == id) == -1) {
        let item = this.filterPermissions.find(p => p.id == id);
        item.read = false;
        item.create = false;
        item.update = false;
        item.delete = false;
        this.agentPermissions.push(item);
        this.showPermission.push(item);
      }
      this.addedPer = null;
    },
    deleteItem(item) {
      let index = this.agentPermissions.findIndex(p => p.id == item.id);
      if (index > -1) this.agentPermissions.splice(index, 1);
      index = this.showPermission.findIndex(
        p => !p.disabled && p.id == item.id
      );
      if (index > -1) this.showPermission.splice(index, 1);
    },
    savePermissions() {
      this.update_loading = true;
      let data = {
        permissions: this.showPermission
          .filter(p => !p.disabled)
          .reduce(
            (arr, p) => [
              ...arr,
              {
                id: p.id,
                create: p.create,
                update: p.update,
                delete: p.delete,
                read: p.read
              }
            ],
            []
          )
      };
      this.$axios
        .put(`/agents/${this.$route.params.agent_id}/permissions`, data)
        .then(({ data }) => {
          this.$bus.$emit("update-agent", data);
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.update_loading = false));
    }
  }
};
</script>

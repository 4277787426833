var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"subtitle":((_vm.profile.fname || ' ') + " " + (_vm.profile.lname || '')),"loading":_vm.loading,"has-actions":""}},[_c('template',{slot:"actions"},[_c('b-dropdown',{key:!!_vm.profile.active,attrs:{"aria-role":"list","position":"is-bottom-left","scrollable":"","max-height":"400px","append-to-body":""}},[_c('div',{attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-button',{attrs:{"icon-left":"dots-vertical","size":_vm.$device.mobile ? 'is-small' : '',"type":"is-default","rounded":""}})],1),(_vm.$can('read', 'settings.agents') && !!_vm.profile.active)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.requestPassword()}}},[_c('b-icon',{attrs:{"icon":"lock","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.$t("request_password"))+" ")],1):_vm._e(),(_vm.$can('delete', 'settings.agents') && !!_vm.profile.active)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.confirmDeleting()}}},[_c('b-icon',{attrs:{"icon":"delete","type":"is-danger","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")],1):_vm._e(),(_vm.$can('delete', 'settings.agents') && !_vm.profile.active)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.restore()}}},[_c('b-icon',{attrs:{"icon":"refresh","type":"is-success","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.$t("restore"))+" ")],1):_vm._e()],1)],1),(!_vm.loading)?_c('div',{staticClass:"profile columns"},[_c('div',{staticClass:"column is-3"},[_c('Card',{staticClass:"menu",attrs:{"content-class":"p-3 columns is-multiline is-mobile m-0"}},[_c('div',{staticClass:"column is-4",class:{
            'bg-deleted': !_vm.profile.active
          }},[_c('img',{staticClass:"avatar",attrs:{"src":"/img/avatars/blank.svg","alt":_vm.profile.fname,"width":"100%"}})]),_c('div',{staticClass:"column is-8 is-flex is-flex-direction-column is-justify-content-center",class:{
            'bg-deleted': !_vm.profile.active
          }},[_c('span',{staticClass:"mb-1"},[_c('b',[_vm._v(_vm._s(_vm.profile.fname)+" "+_vm._s(_vm.profile.lname))])]),_c('div',{staticClass:"is-size-7"},[_c('span',[_vm._v(_vm._s(_vm.profile.role.label))])])]),_c('div',{staticClass:"column is-12"},[_c('hr',{staticClass:"m-0"})]),_c('div',{staticClass:"column is-12 p-0"},[_c('ul',{staticClass:"tabs-container no-wrap"},[_vm._l((_vm.tabs),function(item){return [(
                  item.hasOwnProperty('condition') ? item.condition : true
                )?_c('li',{key:item.value,class:{ active: item.id == _vm.tab },on:{"click":function($event){_vm.tab = item.id}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":item.icon,"custom-size":"mdi-18px"}}),_vm._v(" "+_vm._s(item.value)+" "),(item.comingSoon)?_c('b-tag',{attrs:{"size":"is-small","type":"is-warning"}},[_vm._v(" "+_vm._s(_vm.$t("coming_soon"))+" ")]):_vm._e()],1):_vm._e()]})],2)])])],1),_c('div',{staticClass:"column is-9"},[(!_vm.profile.active)?_c('b-message',{attrs:{"type":"is-danger"}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"account","type":"is-danger"}}),_c('span',{staticClass:"mt-1"},[_c('b',[_vm._v(_vm._s(_vm.$tc("inactive", 1)))])])],1)]):_vm._e(),(_vm.tab == 1)?_c('AgentForm',{attrs:{"profile":_vm.profile,"languages":_vm.languages,"suppliers":_vm.suppliers,"roles":_vm.roles,"warehouses":_vm.warehouses}}):_vm._e(),(_vm.tab == 2)?_c('AgentPermissions',{attrs:{"permissions":{
          role: _vm.profile.role.permissions,
          agent: _vm.profile.permissions
        },"filter-permissions":_vm.permissions,"languages":_vm.languages}}):_vm._e()],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }